// dependencies
import React, { useEffect } from 'react'
import { string, object, bool } from 'prop-types'
import {
  TextField,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Hidden,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Box,
  Button,
  styled,
} from '@mui/material'
import { Field } from 'formik'
import RTGLink from '@shared/link'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
// hooks
import { useModalsContext } from '@context/modalContext'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { CheckCircleOutlined } from '@mui/icons-material'
import { useCreateAccountForm } from './hooks'
import { AuthErrorCodes } from '../../../lib/auth/firebase'
// components
import { RequiredFieldsMessage, PasswordRequirements, FormGrid, InputGrid, SubmitButton } from '../Common'

export const MODAL_ID = 'MODAL/CREATE_NEW_ACCOUNT'

const NoPaddingInputGrid = styled(InputGrid)`
  padding-left: 0 !important;
  padding-right: 14px !important;
`

const NoPaddingGrid = styled(Grid)`
  padding-left: 0 !important;
  padding-right: 14px !important;
`

const createInputInteractionTracking = (dataType = '', action = '') => () => {
  addToDataLayer_nextgen('account_modal_click', {
    account_modal_name: dataType ? `Create Account - ${dataType}` : 'Create Account',
    account_modal_action: action,
  })
}

const StyledHiddenContainer = styled(TextField)({
  display: 'none',
})

const SuccessMessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '140px',
  svg: {
    fill: theme.palette.success.main,
  },
  p: {
    textAlign: 'center',
  },
}))

const CreateNewAccountShort = ({ className, hidePasswordTips, defaultFormValues = {} }) => {
  const { actions } = useModalsContext()

  const callbackSuccess = () => {
    if (typeof window !== 'undefined') window.open('/account/profile', '_blank')
  }

  const callbackError = error => {
    if (error?.code === AuthErrorCodes.EMAIL_EXISTS || error?.code === AuthErrorCodes.INTERNAL_ERROR) {
      actions.genericModal({
        overrideState: {
          theme: 'light',
          componentData: {
            title: 'Account Creation Unsuccessful!',
            message: 'An account currently exists with email ',
            email: defaultFormValues?.email,
          },
          styles: {
            '.MuiPaper-root': {
              maxWidth: '360px',
            },
          },
        },
        data: { PredefinedComponentModal: 'forget_password' },
      })
    }
  }

  const {
    formik,
    showPassword,
    showConfirmPassword,
    onVisibilityToggle,
    onVisibilityToggleConfirmPassword,
    loading,
    handleMouseDownPassword,
    handleMouseDownConfirmPassword,
    successOnCreation,
    errorMessage,
  } = useCreateAccountForm({ callbackSuccess, callbackError })
  const { firstName, lastName, email, password, confirmPassword, zipcode } = formik.values

  const isDisabled = !(firstName && lastName && email && password && confirmPassword && zipcode)

  useEffect(() => {
    if (defaultFormValues?.email) {
      formik.setValues(defaultFormValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFormValues])

  useEffect(() => {
    if (password?.length > 0 && !formik?.touched?.password) {
      formik?.setTouched?.({ password: true })
    }
  }, [formik, password])

  if (successOnCreation) {
    return (
      <Box className={className}>
        <SuccessMessageContainer>
          <CheckCircleOutlined />
          <Typography>Your account has been successfully created!</Typography>
        </SuccessMessageContainer>
      </Box>
    )
  }

  return (
    <Box className={className}>
      <RequiredFieldsMessage requiredText="Required" />
      <FormGrid spacing={2} component="form" container onSubmit={formik.handleSubmit}>
        <StyledHiddenContainer item xs={12} sm={12} md={6}>
          <TextField
            hidden
            size="small"
            fullWidth
            variant="outlined"
            id="firstName"
            autoComplete="given-name"
            name="firstName"
            label="First Name"
            required
            value={firstName}
            onFocus={createInputInteractionTracking('Personal', 'First Name')}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </StyledHiddenContainer>
        <StyledHiddenContainer item xs={12} sm={12} md={6}>
          <TextField
            hidden
            size="small"
            fullWidth
            variant="outlined"
            id="lastName"
            autoComplete="family-name"
            name="lastName"
            label="Last Name"
            required
            value={lastName}
            onFocus={createInputInteractionTracking('Personal', 'Last Name')}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </StyledHiddenContainer>{' '}
        <StyledHiddenContainer item xs={12} sm={12} md={12}>
          <TextField
            hidden
            size="small"
            fullWidth
            variant="outlined"
            id="zipcode"
            autoComplete="postal-code"
            name="zipcode"
            label="Delivery Zip Code"
            required
            value={zipcode}
            onFocus={createInputInteractionTracking('Address', 'Delivery Zip Code')}
            onChange={formik.handleChange}
            error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
            helperText={formik.touched.zipcode && formik.errors.zipcode}
          />
        </StyledHiddenContainer>
        <StyledHiddenContainer item xs={12} sm={12} md={12}>
          <TextField
            hidden
            size="small"
            fullWidth
            variant="outlined"
            id="email"
            autoComplete="email"
            name="email"
            label="Email"
            required
            value={email}
            onChange={formik.handleChange}
            onFocus={createInputInteractionTracking('Personal', 'Email')}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </StyledHiddenContainer>
        <NoPaddingGrid item xs={12} sm={12} md={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="password"
            autoComplete="password"
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            required
            value={password}
            onChange={formik.handleChange}
            onFocus={createInputInteractionTracking('Password', 'Password')}
            error={formik.touched.password && Boolean(formik.errors.password)}
            // helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onVisibilityToggle('password')}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </NoPaddingGrid>
        <NoPaddingGrid item xs={12} sm={12} md={12}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            id="confirmPassword"
            name="confirmPassword"
            autoComplete="configPassword"
            label="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            required
            value={confirmPassword}
            onChange={formik.handleChange}
            onFocus={createInputInteractionTracking('Confirm Password', 'Confirm Password')}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={onVisibilityToggleConfirmPassword('password')}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </NoPaddingGrid>
        {!hidePasswordTips && (
          <NoPaddingGrid item xs={12} sm={12} md={12} style={{ paddingTop: 0, fontSize: '14px' }}>
            <PasswordRequirements
              password={password}
              isTouched={formik.touched.password}
              errors={formik.errors}
              success={!(formik.touched.password && formik.errors.password)}
            />
          </NoPaddingGrid>
        )}
        <NoPaddingInputGrid item xs={12} sm={12} md={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <SubmitButton
              disabled={isDisabled}
              fullWidth
              onFocus={createInputInteractionTracking('Button', 'Submit')}
              color="primary"
              variant="contained"
              size="large"
              type="submit"
            >
              Create Account
            </SubmitButton>
          )}
        </NoPaddingInputGrid>
        {errorMessage && (
          <NoPaddingGrid item xs={12} sm={12} md={12}>
            <Typography color="error" style={{ fontSize: '14px' }}>
              {errorMessage}
            </Typography>
          </NoPaddingGrid>
        )}
      </FormGrid>
    </Box>
  )
}

CreateNewAccountShort.propTypes = {
  className: string,
  defaultFormValues: object,
  hidePasswordTips: bool,
}

export default CreateNewAccountShort
