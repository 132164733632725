import React, { useMemo } from 'react'
import { object, bool } from 'prop-types'
import { styled, Typography, Box } from '@mui/material'
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined'
import { isAndroid, isIOS } from 'react-device-detect'
import { useUserQuery } from '@hooks/auth'
import { useFirebaseContext } from '@context/firebaseContext'
import CreateNewAccountShort from '@components/accounts/CreateNewAccount/CreateNewAccountShort'
import CondensedContact from '@components/shared/condensed-contact-links-v2'
import StrapiLink from '@templates/strapi-cms/content-types/Link'

// Assets
import AppImage from '@assets/images/order-success-mobile-app.png'
import AppStore from '@components/shared/svgs/AppStore'
import GooglePlay from '@components/shared/svgs/GooglePlay'

const SuccessSubheaderDescription = styled(Typography)(({ theme }) => ({
  marginTop: '25px',
  '& > p:first-of-type': {
    color: theme.palette.primary.dark,
    fontSize: '16px',
    fontWeight: 600,
  },
}))

const StyledCreateNewAccountShort = styled(CreateNewAccountShort)(({ theme }) => ({
  width: '300px',
  marginTop: '24px',
  button: {
    maxWidth: 'unset!important',
  },
  span: {
    color: theme.palette.secondary.lighter,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const SuccessContainer = styled(Box, {
  shouldForwardProp: prop => !['supressSigninForm'].includes(prop),
})(({ theme, supressSigninForm }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  ...(supressSigninForm && { maxHeight: '100px' }),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    maxHeight: 'unset',
  },
}))

const AppInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '420px',
  img: {
    width: '137px',
    height: '143px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '65px',
    marginLeft: 'auto',
    marginRight: 'auto',
    img: {
      width: 'auto',
      maxWidth: '100px',
      objectFit: 'cover',
      objectPosition: 'right',
    },
  },
}))

const AppInfoDescription = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  p: {
    fontSize: '25px',
    fontWeight: 500,
    color: theme.palette.primary.dark,
    textAlign: 'center',
  },
}))

const SuccessHeaderText = styled('h1')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  '&, & > p, & > svg': {
    color: theme.palette.success.main,
    fontSize: '18px',
    fontWeight: 400,
    textTransform: 'capitalize',
    margin: 0,
    textAlign: 'start',
  },
  svg: {
    marginRight: '8px',
    width: '24px',
    height: '24px',
  },
}))

const SuccessHeaderDescription = styled(Typography)(() => ({
  span: {
    fontWeight: 700,
  },
}))

const BoxColor = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  width: '150px',
  height: '46px',
  marginTop: '16px',
  borderRadius: '8px',
  svg: {
    height: '100%',
    path: {
      fill: '#F2F2F2',
    },
  },
}))

const StyledCondensedContact = styled(CondensedContact)(({ theme }) => ({
  marginTop: '60px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const SuccessInfoContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export default function SuccessHeader({ contact, shippingAddress, showAccountForm }) {
  const { user } = useFirebaseContext()

  const showCreateAccountForm = user?.email !== contact?.email && showAccountForm

  const defaultFormValues = useMemo(
    () => ({
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      email: contact?.email,
      zipcode: shippingAddress?.zip,
    }),
    [contact, shippingAddress],
  )

  return (
    <SuccessContainer supressSigninForm={!showCreateAccountForm}>
      <SuccessInfoContainer>
        <SuccessHeaderText>
          <CheckCircleOutlined />
          <Typography>{`${contact?.firstName}, Your Order Has Been Submitted Successfully!`}</Typography>
        </SuccessHeaderText>
        <SuccessHeaderDescription>
          <Typography>
            A confirmation email will be sent to <span>{contact?.email ?? 'you'}</span>
          </Typography>
          <Typography>
            For questions or concerns regarding your order, please contact Customer Support at (888) 709-5380
          </Typography>
        </SuccessHeaderDescription>
        {/* CREATE ACCOUNT FORM */}
        {showCreateAccountForm && (
          <>
            <SuccessSubheaderDescription>
              <Typography>Save your information for next time.</Typography>
              <Typography>
                Create an account today for a faster checkout during your next purchase, save favorites, view order
                status and more.
              </Typography>
            </SuccessSubheaderDescription>
            <StyledCreateNewAccountShort defaultFormValues={defaultFormValues} />
          </>
        )}
      </SuccessInfoContainer>
      <AppInfoContainer>
        <img src={AppImage} alt="mobile-app" />
        <AppInfoDescription>
          <Typography>Download the app to track your delivery</Typography>
          {!isAndroid && (
            <BoxColor>
              <StrapiLink
                className="footer__store-downloads__store-icon"
                data={{
                  ExternalUrl: 'https://apps.apple.com/us/app/room-to-go/id898852501',
                  displayText: 'Get the Rooms To Go App',
                  id: 'rtg-app-ios',
                }}
                trackingData={{
                  category: 'footer',
                  action: 'click',
                  label: 'RTG iOS app download',
                }}
              >
                <AppStore />
              </StrapiLink>
            </BoxColor>
          )}
          {!isIOS && (
            <BoxColor>
              <StrapiLink
                className="footer__store-downloads__store-icon"
                data={{
                  ExternalUrl: 'https://play.google.com/store/apps/details?id=com.roomstogo.dealstogo',
                  displayText: 'Get the Rooms To Go App',
                  id: 'rtg-app-android',
                }}
                trackingData={{
                  category: 'footer',
                  action: 'click',
                  label: 'RTG Android app download',
                }}
              >
                <GooglePlay />
              </StrapiLink>
            </BoxColor>
          )}
          <StyledCondensedContact />
        </AppInfoDescription>
      </AppInfoContainer>
    </SuccessContainer>
  )
}

SuccessHeader.propTypes = {
  contact: object,
  shippingAddress: object,
  showAccountForm: bool,
}
